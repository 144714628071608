import axios from "axios";

const url =
  process.env.REACT_APP_API_URL ?? "https://arvincare-api.vigee.tech/api";
 

export const clientCredentialAxios = axios.create({
  baseURL: (axios.defaults.baseURL = url),
});

export const authApiAxios = axios.create({
  baseURL: (axios.defaults.baseURL = url),
});

authApiAxios.defaults.headers.common["Authorization"] =
  localStorage.getItem("token");
//https://apiarvincare.manciomarket.com/api/
//http://127.0.0.1:8000/api/
//https://api.arvin-care.com/
//https://arvincare-api.vigee.fr/
