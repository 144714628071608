import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
//------------------ Components ---------------------
import LoadingButton from "../../Commons/LoadingButton/LoadingButton";
import Datetime from "react-datetime";
import moment from "moment";
import { Fragment } from "react";
import { Divider } from "@material-ui/core";

const Step1 = ({
  loadingDepartue,
  choosenDepartue,
  departues,
  errorsForm,
  onSelectDepartueHandler,
  choosenPlace,
  loadingPlace,
  onChangeDate,
  onSelectPlaceHandler,
  departueDate,
  returnDate,
  onClickGetAQuote,
  timeArrival,
  timeDepatue,
  choosenTimeArrival,
  choosenTimeDepartue,
  onSelectTime,
  onChangePromoCode,
  promoCodeField,
}) => {
  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  const hasNotGoodDay = (time /*Time is 00:00 - 00:15 */) => {
    if (
      //It delete the bed hours
      parseInt(moment().add(2, "hours").format("HH"), 10) >
        parseInt(time.substr(0, 2), 10) &&
      moment().format("DD/MM/yyyy") ===
        moment(departueDate)?.format("DD/MM/yyyy")
    ) {
      return true;
    }

    if (
      moment().format("DD/MM/yyyy") ===
        moment(departueDate)?.format("DD/MM/yyyy") && //same date departue as today
      parseInt(moment().format("HH"), 10) < 7 &&
      (parseInt(moment().format("HH"), 10) >= 0 ||
        parseInt(moment().format("HH"), 10) === 12 ||
        parseInt(moment().format("HH"), 10) === 24) &&
      parseInt(time.substr(0, 2), 10) < 12
    ) {
      return true;
    }
    return false;
  };

  // POUR LES JOURS D'ABSENCE TO BLOCK SOME SHIFTS WITH HARDCODED METHOD
  const isHoliday = (time /*Time is 00:00 - 00:15 */) => {
    let jour1 = moment(new Date(2022, 3, 29)).format("DD/MM/yyyy");
    let jour2 = moment(new Date(2022, 3, 30)).format("DD/MM/yyyy");
    let jour3 = moment(new Date(2022, 4, 1)).format("DD/MM/yyyy");
    if (
      jour1 === moment(departueDate).format("DD/MM/yyyy") ||
      jour2 === moment(departueDate).format("DD/MM/yyyy") ||
      jour3 === moment(departueDate).format("DD/MM/yyyy") //same date departue as today
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Row className={"rowStyle"}>
        <Col xs={12} lg={4}>
          {loadingDepartue ? (
            <LoadingButton />
          ) : (
            <>
              <Row className={"rowStyle"}>
                <Col className="text-left">
                  <span className="labelStyleSteps">Prise en charge aller</span>
                </Col>
              </Row>
              <Row className={"rowStyle"}>
                <Col>
            
                  <DropdownButton
                    title={
                      Object.keys(choosenDepartue).length !== 0
                        ? choosenDepartue.name
                        : "Sélectionnez un lieu"
                    }
                    className={"dropDown"}
                  >
                    <Dropdown.Item key={0} disabled={true}>
                      <span>Sélectionnez un lieu</span>
                    </Dropdown.Item>

                  </DropdownButton>
                </Col>
              </Row>
            </>
          )}
          <Row className={"rowStyle"}>
            <Col>
              <span className="text-danger">
                {errorsForm.departue
                  ? "Choisir le lieu de prise en charge"
                  : null}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <Row className={"rowStyle"}>
            <Col className="text-left">
              <span className="labelStyleSteps">Date de l'aller</span>
            </Col>
          </Row>
          <Datetime
            timeFormat={false}
            onChange={(event) => onChangeDate(event, "depature_date")}
            value={departueDate}
            dateFormat={"D/M/Y"}
            inputProps={{ className: "dateTimeStyle",readOnly: true }}
            isValidDate={disablePastDt}
          />

          <Row className={"rowStyle"}>
            <Col>
              <span className="text-danger">
                {errorsForm.dateDiscrepancy
                  ? "choisissez les bonnes dates"
                  : null}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <Row className={"rowStyle"}>
            <Col className="text-left">
              <span className="labelStyleSteps">Horaire prise en charge </span>
            </Col>
          </Row>
          <DropdownButton
            title={
              Object.keys(choosenTimeDepartue).length
                ? choosenTimeDepartue.name
                : "Sélectionnez un horaire"
            }
            className={"dropDown"}
          >
            <Dropdown.Item key={0} disabled={true}>
              <span>Sélectionnez un horaire</span>
            </Dropdown.Item>
            {timeDepatue?.map((time, index) => {
              return time.no_click ||
                hasNotGoodDay(time.name) ||
                isHoliday(time.name) ? (
                <Dropdown.Item key={time.id} disabled>
                  <span>{time.name} ( complet )</span>
                </Dropdown.Item>
              ) : (
                /*: moment()
                  .startOf("day")
                  .diff(moment(departueDate)?.startOf("day"), "days") === 0 ? (
                !time.no_click_today ? (
                  <Dropdown.Item
                    key={time.id}
                    onSelect={() => onSelectTime(time, "departue")}
                  >
                    <span>{time.name}</span>
                  </Dropdown.Item>
                ) : null
              ) :*/ <>
                  <Dropdown.Item
                    key={time.id}
                    onSelect={() => onSelectTime(time, "departue")}
                  >
                    <span>{time.name}</span>
                  </Dropdown.Item>
                </>
              );
            })}
          </DropdownButton>
          <Row className={"rowStyle"}>
            <Col>
              <span className="text-danger">
                {errorsForm.time_departue ? "choisissez l'horaire" : null}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          {loadingPlace ? (
            <LoadingButton />
          ) : (
            <>
              <Row className={"rowStyle"}>
                <Col className="text-left">
                  <span className="labelStyleSteps">
                    Prise en charge retour
                  </span>
                </Col>
              </Row>
              <Row className={"rowStyle"}>
                <Col>
                  <DropdownButton
                    title={
                      Object.keys(choosenPlace).length !== 0
                        ? choosenPlace.name
                        : "Sélectionnez un lieu"
                    }
                    className={"dropDown"}
                  >
                    <Dropdown.Item key={0} disabled={true}>
                      <span>Sélectionnez un lieu</span>
                    </Dropdown.Item>

                  </DropdownButton>
                </Col>
              </Row>
            </>
          )}
          <Row className={"rowStyle"}>
            <Col>
              <span className="text-danger">
                {errorsForm.place ? "Choisir point de prise en charge" : null}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <Row className={"rowStyle"}>
            <Col className="text-left">
              <span className="labelStyleSteps">Date du retour</span>
            </Col>
          </Row>
          <Datetime
            timeFormat={false}
            onChange={(event) => onChangeDate(event, "return_date")}
            value={returnDate}
            dateFormat={"D/M/Y"}
            inputProps={{ className: "dateTimeStyle",readOnly: true  }}
            isValidDate={disablePastDt}
          />
        </Col>

        <Col xs={12} lg={4}>
          <Row className={"rowStyle"}>
            <Col className="text-left">
              <span className="labelStyleSteps">Horaire retour véhicule</span>
            </Col>
          </Row>
          <DropdownButton
            title={
              Object.keys(choosenTimeArrival).length
                ? choosenTimeArrival.name
                : "Sélectionnez un horaire"
            }
            className={"dropDown"}
          >
            <Dropdown.Item key={0} disabled={true}>
              <span>Sélectionnez un horaire</span>
            </Dropdown.Item>
            {timeArrival?.map((time, index) => {
              return time.no_click || isHoliday(time.name) ? (
                <Dropdown.Item key={index} disabled>
                  <span>{time.name} ( complet )</span>
                </Dropdown.Item>
              ) : moment()
                  .startOf("day")
                  .diff(moment(returnDate)?.startOf("day"), "days") === 0 ? (
                !time.no_click_today ? (
                  <Dropdown.Item
                    key={time.id}
                    onSelect={() => onSelectTime(time, "return")}
                  >
                    <span>{time.name}</span>
                  </Dropdown.Item>
                ) : null
              ) : (
                <>
                  <Dropdown.Item
                    key={time.id}
                    onSelect={() => onSelectTime(time, "return")}
                  >
                    <span>{time.name}</span>
                  </Dropdown.Item>
                </>
              );
            })}
          </DropdownButton>

          <Row className={"rowStyle"}>
            <Col>
              <span className="text-danger">
                {errorsForm.time_return ? "choisissez l'horaire" : null}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Col xs={12} lg={12}>
        <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
      </Col>

      <Row>
        <Col xs={12} md={8}>
          <span style={{ padding: "10px" }}>Vous avez un code promo ?</span>
          <input
            className="inputStyle"
            placeholder={"ex : monCodePromo"}
            style={{ width: "200px", marginBottom: 10 }}
            onChange={(e) => onChangePromoCode(e.target.value)}
            value={errorsForm.promoCode}
          />

          <Col xs={12} lg={12}>
            <span className="text-danger">
              {errorsForm.promo_code && "ce code n'existe pas"}
            </span>
          </Col>
        </Col>

        <Col xs={12} md={4} style={{ paddingTop: "10px" }}>
          <Button
            className="buttonGeneralStyleBlack"
          
            onClick={() => onClickGetAQuote()}
          >
            Obtenir mon tarif
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Step1;
