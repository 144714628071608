import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { Navbar, Nav } from "react-bootstrap";
//------------------ Components ---------------------
import Step1 from "../../Components/Clients/Step1/Step1";
import Step2 from "../../Components/Clients/Step2/Step2";
import Step3 from "../../Components/Clients/Step3/Step3";
import Step4 from "../../Components/Clients/Step4/Step4";
import HowItWorks from "../../Components/Clients/HowItWorks/HowItWorks";
import MobileAppBanner from "../../Components/Commons/MobileAppBanner/MobileAppBanner";
import GoogleReviews from "../../Components/Clients/Google/GoogleReviews";

//---------------- Other import ----------------
import Background from "../../Assets/Images/background.png";
import * as clientCredential from "../../Axios/Call/ClientCredential";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as authApi from "../../Axios/Call/AuthApi";
import classes from "./Home.module.css";
import { useWindowWidth } from "@react-hook/window-size";
import logo from "../../Assets/Images/Logo_ArvinCare_Voiturier.png";
import logoWhite from "../../Assets/Images/Logo_Arvin-Care_white.png";
import * as actionCreator from "../../store/action/index";
import Avantages from "../../Components/Clients/Avantages/Avantages";
import Socials from "../../Components/Clients/Socials/Socials";
import Sanification from "../../Components/Clients/Sanification/Sanification";
import { FaSignOutAlt } from "react-icons/fa";
import vigeeLogo from "../../Assets/Images/vigeeWhite.png";
import { Helmet } from "react-helmet";

const Home = () => {
  // COOKIES
  const [accepted, setAccepted] = useState(false);

  const userData = useSelector(state => state.user.userData);
  const history = useHistory();
  const dispatch = useDispatch();
  const [departues, setDepartues] = useState([]);
  const [timeArrival, setTimeArrival] = useState([]);
  const [timeDepartue, setTimeDepartue] = useState([]);
  const [departueDate, setDepartueDate] = useState(moment());
  const [returnDate, setReturnDate] = useState(moment());
  const [choosenDepartue, setChoosenDepartue] = useState({});
  const [choosenPlace, setChoosenPlace] = useState({});
  const [choosenTimeDepartue, setChoosenTimeDepartue] = useState({});
  const [choosenTimeArrival, setChoosenTimeArrival] = useState({});
  const [calculatedPrice, setCalculatedPrice] = useState({});
  const [promoCodeField, setPromoCodeField] = useState();
  const [promoCode, setPromoCode] = useState();

  const [step, setStep] = useState(1);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [choosenAdditionalServices, setChoosenAdditionalService] = useState([]);
  const [choosenInsurance, setChoosenInsurance] = useState();

  const [plate, setPlate] = useState("");
  const [carkm, setCarKm] = useState("");
  const [hasbaggage, setHasbaggage] = useState(false);
  const [departueDestination, setDepartueDestination] = useState("");
  const [returnDestination, setReturnDestination] = useState("");
  //----Loading/Error/Success
  const [loadingDepartue, setLoadingDepartue] = useState(false);

  const [errorsForm, setErrorsForm] = useState({
    departueDate: false,
    returnDate: false,
    dateDiscrepancy: false,
    departue: false,
    place: false,
    plate: false,
    carKm: false,
    hasbaggage: false,
    time_departue: false,
    time_return: false,
    promo_code: false,
    departueDestination: false,
    returnDestination: false,
  });

  const screenWidth = useWindowWidth();
  const current = new Date();

  const google = social => {
    if (social === "tel" && accepted) {
      window.gtag("event", "intention-appel", {
        event_category: "contact",
        event_label: "footer",
      });
    }
  };

  useEffect(() => {
    if (accepted) {
      const scriptTag = document.createElement("script");
      scriptTag.src =
        "https://www.googletagmanager.com/gtag/js?id=UA-185657986-1";
      scriptTag.addEventListener("accepted", () => setAccepted(true));
      document.body.appendChild(scriptTag);
    } else {
    }
  }, [accepted]);

  useEffect(() => {
    setLoadingDepartue(true);
    clientCredential
      .departues()
      .then(response => {
        setDepartues(response.data);
        setLoadingDepartue(false);
      })
      .catch(error => {
        setLoadingDepartue(false);
      });

    authApi
      .getAdditionalServicesClient()
      .then(response => {
        setAdditionalServices(response.data);
      })
      .catch(error => {});
  }, []);

  useEffect(() => {
    if (Object.keys(choosenDepartue).length) {
      setErrorsForm({ ...errorsForm, departue: false });
    }
  }, [choosenDepartue]);

  useEffect(() => {
    if (departueDate) {
      authApi
        .timeDepatue({ data: moment(departueDate).format("yyyy-MM-DD") })
        .then(response => {
          setTimeDepartue(response.data);
        })
        .catch(erorr => {});
    }
  }, [departueDate]);

  useEffect(() => {
    if (returnDate) {
      authApi
        .timeReturn({ data: moment(returnDate).format("yyyy-MM-DD") })
        .then(response => {
          setTimeArrival(response.data);
        })
        .catch(erorr => {});
    }
  }, [returnDate]);

  useEffect(() => {
    authApi
      .getPromoCodeByCode(promoCodeField)
      .then(res => {
        if (res.data.is_active === 1) {
          setPromoCode(res.data);
        }
      })
      .catch(error => {
        setPromoCode();
      });
  }, [promoCodeField]);

  useEffect(() => {
    if (step === 2) {
      let days = returnDate.diff(departueDate, "days")
        ? returnDate.diff(departueDate, "days")
        : 1;
      clientCredential
        .getCalculatedPrice({
          departue_id:
            choosenPlace === choosenDepartue ? choosenDepartue.id : 2,
          days: days,
        })
        .then(response => {
          let data = response.data;

          if (promoCodeField) {
            let priceWithPromo = data.total_our_price;
            let totalTheirPrice = data.total_their_price;
            if (promoCode.type === "price") {
              priceWithPromo -= promoCode.price;
            } else if (promoCode.type === "percentage") {
              priceWithPromo = (1 - promoCode.price / 100) * priceWithPromo;
            }
            data = {
              ...data,
              total_our_price: priceWithPromo,
              percentage: totalTheirPrice - priceWithPromo,
            };
          }

          setCalculatedPrice(data);
        })
        .catch(error => {});
    }
  }, [step]);

  const onSelectDepartueHandler = departue => {
    setChoosenDepartue(departue);
    localStorage.setItem("departue", JSON.stringify(departue));
  };

  const onSelectPlaceHandler = place => {
    setChoosenPlace(place);
    localStorage.setItem("place", JSON.stringify(place));
    setErrorsForm({ ...errorsForm, place: false });
  };

  const onChangeDate = (date, type) => {
    if (type === "depature_date") {
      setDepartueDate(date);
      localStorage.setItem("departueDate", JSON.stringify(date));
      setChoosenTimeDepartue({});
    } else {
      setReturnDate(date);
      localStorage.setItem("returnDate", JSON.stringify(date));
      setChoosenTimeArrival({});
    }
  };

  const onClickGetAQuote = () => {
    if (!Object.keys(choosenDepartue).length) {
      setErrorsForm({ ...errorsForm, departue: true });
      return false;
    }
    if (!Object.keys(choosenPlace).length) {
      setErrorsForm({ ...errorsForm, place: true });
      return false;
    }
    if (!Object.keys(choosenTimeArrival).length) {
      setErrorsForm({ ...errorsForm, time_return: true });
      return false;
    }
    if (!Object.keys(choosenTimeDepartue).length) {
      setErrorsForm({ ...errorsForm, time_departue: true });
      return false;
    }
    if (
      moment(departueDate).add(1, "day") > moment(returnDate).add(1, "day") ||
      new Date() > moment(departueDate).add(1, "day")
    ) {
      setErrorsForm({ ...errorsForm, dateDiscrepancy: true });
      return false;
    }

    if (promoCodeField) {
      if (!promoCode) {
        setErrorsForm({ ...errorsForm, promo_code: true });
        return false;
      }
    }

    setErrorsForm({
      ...errorsForm,
      dateDiscrepancy: false,
      place: false,
      departue: false,
    });
    setStep(2);
  };

  const onClickSecondStepDone = () => {
    setStep(3);
  };

  const onClickThirdStepDone = () => {
    if (!choosenInsurance) {
      setErrorsForm({ ...errorsForm, choosenInsurance: true });
      return false;
    }

    if (!plate || !departueDestination || !returnDestination) {
      setErrorsForm({
        ...errorsForm,
        plate: !plate,
        departueDestination: !departueDestination,
        returnDestination: !returnDestination,
      });
      return false;
    }
    setStep(4);
  };

  const onChangeExtraOptionHandler = service => {
    if (
      choosenAdditionalServices.find(
        choosenService => choosenService.id === service.id
      )
    ) {
      let newChoosenAdditionalServices = [...choosenAdditionalServices];
      const index = newChoosenAdditionalServices.findIndex(
        choosenService => choosenService.id === service.id
      );
      newChoosenAdditionalServices.splice(index, 1);
      setChoosenAdditionalService(newChoosenAdditionalServices);
    } else {
      setChoosenAdditionalService(choosenAdditionalServices.concat(service));
    }
  };

  const onChangeInsuranceHandler = insurance => {
    if (choosenInsurance?.id === insurance?.id) {
      setChoosenInsurance();
    } else {
      setChoosenInsurance(insurance);
    }
  };

  const renderPrice = () => {
    let price = parseFloat(calculatedPrice?.total_our_price) || 0;

    // Arrondir à 5 au-dessus
    let insurance =
      Math.ceil(((choosenInsurance?.percent / 100) * price) / 5) * 5;
    price += parseFloat(insurance) || 0;

    choosenAdditionalServices?.forEach(choosenService => {
      price += parseFloat(choosenService?.price) || 0;
    });

    return price.toFixed(2);
  };

  const changeDetail = (text, type) => {
    if (type === "plate") {
      setPlate(text);
    }

    if (type === "carkm") {
      setCarKm(text);
    }

    if (type === "hasbaggage") {
      setHasbaggage(text);
    }

    if (type === "departueDestination") {
      setDepartueDestination(text);
    }

    if (type === "returnDestination") {
      setReturnDestination(text);
    }
  };

  const editInformation = () => {
    setStep(1);
  };

  const onClickLogoutHandler = () => {
    dispatch(actionCreator.cleanUserData());
    history.push("/");
  };

  const onSelectTime = (time, type) => {
    if (type === "departue") {
      setChoosenTimeDepartue(time);
    }
    if (type === "return") {
      setChoosenTimeArrival(time);
    }
  };

  const onChangePromoCode = code => {
    setPromoCodeField(code);
  };

  return (
    <>
      <Helmet>
        <meta
          http-equiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
      </Helmet>

      <MobileAppBanner />

      <Container fluid style={{ margin: 0, padding: 0 }}>
        <Container
          fluid
          style={{
            margin: 0,
            padding: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundImage: `url(${Background})`,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
        >
          <Navbar className={classes.navbar}>
            <Container fluid>
              <Navbar.Brand href="/">
                <Image
                  src={logo}
                  style={{ width: "150px", float: "center" }}
                  onClick={() => setStep(1)}
                />
              </Navbar.Brand>

              <Nav className="me-auto">
                <Row style={{}}>
                  {Object.keys(userData).length ? (
                    screenWidth > 1025 ? (
                      <Col className="text-right">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            className={classes.buttonGeneralStyleBlack}
                            style={{ width: "auto" }}
                            onClick={() => history.push("/profil")}
                          >
                            Mon compte
                          </Button>
                          <Button
                            onClick={() => onClickLogoutHandler()}
                            className={classes.buttonGeneralStyleBlack}
                            style={{ width: "auto", marginLeft: 10 }}
                          >
                            <FaSignOutAlt
                              size={20}
                              color={"white"}
                              style={{ paddingRight: 0 }}
                            />
                          </Button>
                        </div>
                      </Col>
                    ) : (
                      <>
                        <Col
                          className="text-center"
                          xs={9}
                          style={{ marginTop: 10 }}
                        >
                          <Button
                            onClick={() => history.push("/profil")}
                            className={classes.buttonGeneralStyle}
                          >
                            {userData.name} {userData.surname}
                          </Button>
                        </Col>
                        <Col
                          className="text-center"
                          xs={3}
                          style={{ marginTop: 10 }}
                        >
                          <Button
                            onClick={() => onClickLogoutHandler()}
                            className={classes.buttonGeneralStyle}
                          >
                            <FaSignOutAlt
                              size={20}
                              color={"#FFF "}
                              style={{ paddingRight: 1 }}
                            />
                          </Button>
                        </Col>
                      </>
                    )
                  ) : null}
                </Row>
                {screenWidth > 1025 ? (
                  <Col className="text-right">
                    {!Object.keys(userData).length ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Link to="/login">
                          <Button
                            className={classes.buttonGeneralStyleBlack}
                            style={{ width: 200 }}
                          >
                            Connexion
                          </Button>
                        </Link>
                      </div>
                    ) : null}
                  </Col>
                ) : null}
              </Nav>
            </Container>
          </Navbar>

          <Container fluid>
            <Row
              style={{
                paddingBottom: 160,

                opacity: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                minHeight: step === 1 ? "71vh" : "100vh",
              }}
            >
              <Col>
                <Row>
                  <Col
                    xs={12}
                    lg={{
                      span: step === 3 ? 8 : 10,
                      offset: step === 3 ? 2 : 1,
                    }}
                    xl={{
                      span: step === 3 ? 6 : 8,
                      offset: step === 3 ? 3 : 2,
                    }}
                  >
                    {step === 1 ? (
                      <>
                        <Row style={{ marginTop: "4.5rem" }}>
                          <Col xs={12} lg={{ span: 12, offset: 0 }}>
                            <span className={classes.title}>
                              Votre parking avec voiturier
                            </span>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "-1rem" }}>
                          <Col xs={12} lg={{ span: 12, offset: 0 }}>
                            <span className={classes.subTitle}>
                              Au départ de la gare et de l’aéroport de Rennes
                            </span>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                    <Card
                      style={{
                        marginTop: "1rem",
                        padding: 40,
                        maxWidth: "1090px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        borderRadius: 6,
                      }}
                    >
                      {step === 1 ? (
                        <Step1
                          loadingDepartue={loadingDepartue}
                          choosenDepartue={choosenDepartue}
                          departues={departues}
                          errorsForm={errorsForm}
                          onSelectDepartueHandler={onSelectDepartueHandler}
                          choosenPlace={choosenPlace}
                          returnDate={returnDate}
                          onChangeDate={onChangeDate}
                          onSelectPlaceHandler={onSelectPlaceHandler}
                          departueDate={departueDate}
                          onClickGetAQuote={onClickGetAQuote}
                          timeArrival={timeArrival}
                          timeDepatue={timeDepartue}
                          choosenTimeArrival={choosenTimeArrival}
                          choosenTimeDepartue={choosenTimeDepartue}
                          onSelectTime={onSelectTime}
                          onChangePromoCode={onChangePromoCode}
                          promoCodeField={promoCodeField}
                        />
                      ) : null}
                      {step === 2 ? (
                        <Step2
                          returnDate={returnDate}
                          departueDate={departueDate}
                          choosenPlace={choosenPlace}
                          onClickSecondStepDone={onClickSecondStepDone}
                          choosenDepartue={choosenDepartue}
                          editInformation={editInformation}
                          calculatedPrice={calculatedPrice}
                          choosenTimeArrival={choosenTimeArrival}
                          choosenTimeDepartue={choosenTimeDepartue}
                          promoCode={promoCode}
                        />
                      ) : null}
                      {step === 3 ? (
                        <Step3
                          plate={plate}
                          hasbaggage={hasbaggage}
                          choosenDepartue={choosenDepartue}
                          choosenPlace={choosenPlace}
                          carkm={carkm}
                          departueDestination={departueDestination}
                          returnDestination={returnDestination}
                          onClickThirdStepDone={onClickThirdStepDone}
                          onChangeExtraOptionHandler={
                            onChangeExtraOptionHandler
                          }
                          onChangeInsuranceHandler={onChangeInsuranceHandler}
                          calculatedPrice={calculatedPrice}
                          renderPrice={renderPrice}
                          changeDetail={changeDetail}
                          errorsForm={errorsForm}
                          additionalServices={additionalServices}
                          choosenAdditionalServices={choosenAdditionalServices}
                        />
                      ) : null}
                      {step === 4 ? (
                        <Step4
                          renderPrice={renderPrice}
                          plate={plate}
                          hasbaggage={hasbaggage}
                          carkm={carkm}
                          arrivalDate={returnDate}
                          departueDate={departueDate}
                          choosenPlace={choosenPlace}
                          choosenDepartue={choosenDepartue}
                          choosenAdditionalServices={choosenAdditionalServices}
                          choosenTimeArrival={choosenTimeArrival}
                          choosenTimeDepartue={choosenTimeDepartue}
                          calculatedPrice={calculatedPrice}
                          departueDestination={departueDestination}
                          returnDestination={returnDestination}
                          setStep={setStep}
                          insurance={choosenInsurance}
                        />
                      ) : null}
                    </Card>
                  </Col>
                </Row>
                {screenWidth < 1026 && !Object.keys(userData).length ? (
                  <>
                    <Row style={{ marginTop: "2rem" }}>
                      <Col xs={12}>
                        <Link to="/login">
                          <Button
                            className={classes.buttonGeneralStyle}
                            style={{ width: 200 }}
                          >
                            Se Connecter
                          </Button>
                        </Link>
                      </Col>
                      <Col style={{ marginTop: 10 }}>
                        <Link to="register">
                          <Button
                            className={classes.buttonGeneralStyle}
                            style={{ width: 200 }}
                          >
                            Créer un compte
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Col>
            </Row>
          </Container>
        </Container>

        <HowItWorks />
      </Container>
      <Container fluid>
        <Avantages />
      </Container>
      <Container fluid>
        <Socials />
        <Row
          className={classes.rowStyle}
          style={{ backgroundColor: "#232D4C" }}
        >
          <Col xs={12} lg={3} className="my-auto">
            <Row style={{ marginTop: 50 }}>
              <Col>
                <a href="https://www.ac-conciergerie.fr" target="_blank">
                  <Image
                    src={logoWhite}
                    style={{ marginBottom: 20, width: "200px" }}
                  />
                </a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={1}></Col>
          <Col xs={12} lg={{ span: 7 }}>
            <Row style={{ marginTop: 30 }}>
              <Col xs={4} lg={3}>
                <h5>ARVIN CARE</h5>
                <br />
                <br />

                <a
                  href="https://www.ac-conciergerie.fr"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <span className={classes.footerFont}>Autres services</span>
                </a>
                <br />
                <a
                  href="https://www.ac-conciergerie.fr/contact/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <span className={classes.footerFont}>Contact</span>
                </a>
              </Col>

              <Col xs={4} lg={3}>
                <h5>INFOS</h5>
                <br />
                <br />
                <a
                  href="https://www.ac-conciergerie.fr/mentions-legales/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <span className={classes.footerFont}>Mentions légales</span>
                </a>
                <br />
                <a
                  href="https://www.ac-conciergerie.fr/politique-de-confidentialite/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <span className={classes.footerFont}>
                    Politique de confidentialité
                  </span>
                </a>
              </Col>

              <Col xs={4} lg={3}>
                <h5>CONTACT</h5>
                <br />
                <br />
                <a
                  className={classes.footerFont}
                  href="tel:0982695980"
                  onClick={() => google("tel")}
                >
                  Appelez-nous
                </a>

                <br />
                <a
                  className={classes.footerFont}
                  href="mailto:contact@arvin-care.com"
                >
                  contact@arvin-care.com
                </a>
              </Col>
            </Row>
          </Col>

          <Col style={{ paddingBottom: 30 }} xs={12} lg={12}>
            <div style={{ paddingLeft: 0, marginTop: -10 }}>
              <span style={{ color: "#EEE", fontSize: 16 }}>
                {" "}
                © {`${current.getFullYear()}`} ARVIN CARE Service Conciergerie à
                Rennes |{" "}
              </span>

              <span style={{ color: "#EEE", fontSize: 16 }}>
                Une application développée par{" "}
                <a
                  href="https://www.vigee.fr"
                  target="_blank"
                  style={{ color: "#FFF", fontSize: 16 }}
                >
                  <img
                    src={vigeeLogo}
                    width={80}
                    alt="Vigee"
                    style={{ pointer: "cursor" }}
                  />
                </a>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
